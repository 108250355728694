import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FasciaPaginaGenerica from "../components/fascia-pagina-generica";
import withPreview from "../utility/with-preview";

const PaginaGenericaTemplate = ({ data, location }) => {
  const { title, link, seo, fasce, navMenu, topMenu, theme } = data.page;
  return (
    <Layout
      location={location}
      navMenu={navMenu}
      topMenu={topMenu?.value}
      theme={theme?.value?.[0]}
    >
      <Seo
        title={seo?.content?.htmlTitle?.value || title?.value}
        description={seo?.content?.metaDescription?.value}
        ogImage={seo?.content?.ogImage?.node}
        path={link}
      />
      <div data-template="pagina-generica">
        {fasce?.map((fascia, i) => (
          <FasciaPaginaGenerica
            fascia={fascia.value}
            key={i}
            location={location}
            title={seo?.content?.htmlTitle?.value || title?.value}
          />
        ))}
      </div>
    </Layout>
  );
};

export default withPreview(PaginaGenericaTemplate, {
  subField: "page",
  fixed: true,
});
export const query = graphql`
  query ($id: String) {
    page: liferayJskLayoutPaginaGenerica(id: { eq: $id }) {
      liferayFields {
        siteId
        articleId
      }
      link
      seo {
        content {
          htmlTitle {
            value
          }
          metaDescription {
            value
          }
          ogImage {
            node {
              resize(width: 1200) {
                src
                width
                height
              }
            }
          }
          metaRobots {
            value
          }
        }
      }
      title {
        value
      }
      fasce {
        value {
          ...FasciaPaginaGenericaFragment
        }
      }
      navMenu {
        ...MenuNavigazioneFragment
      }
      topMenu {
        value {
          ...JskTopMenuFragment
        }
      }
      theme {
        value
      }
    }
  }
`;
